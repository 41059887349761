.work_with_us_container {
  padding: 0 var(--px);

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      margin: var(--mb) 0;
      max-width: 99.5rem;
      width: 100%;
      text-align: center;
      color: var(--black-tint-3);
    }

    .why_container {
      width: 100%;
      background-color: var(--yellow-light);
      border-radius: 2.4rem;
      padding: 6.4rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 4.8rem;
      margin-bottom: var(--mb);

      .why_container-left {
        max-width: 39.4rem;
        width: 100%;
        color: var(--black-tint-3);

        h2 {
          margin-bottom: 1.6rem;
        }
      }

      .why_container-right {
        display: flex;
        flex-direction: column;
        gap: 3.2rem;
        max-width: 48.6rem;
        width: 100%;

        .reason_card {
          display: flex;
          align-items: flex-start;

          h2 {
            margin-right: 4rem;
            color: var(--yellow-main);
          }
        }
      }

      @media screen and (max-width: 768px) {
        padding: 4rem 1.6rem;

        .why_container-right,
        .why_container-left {
          max-width: 100%;
        }
      }
    }
  }
}
