.product_container {
  padding: 0 var(--px);
  margin-bottom: var(--mb);

  section {
    display: flex;
    flex-direction: column;
    align-items: center;

   

    .slide-show{
      width: 100%;
      padding: 20px;
      .img-slide{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        max-width: 65vw;
        margin-left: auto;
        margin-right: auto;
        @media screen and (max-width: 900px) {
          max-width: 100%;
          p{
            display: none;
          }
        }
        
        img{
          width: 100%;
          height: 100%;
        }
        .desc-sli{
          width: 100%;
          padding: 20px;
          position: absolute;
          bottom: 0;
          left: 0;
          color: white;
          z-index: 1;
  //         background: radial-gradient(
  //   circle at bottom left,
  //   #5b2501,
  //   var(--yellow-tint-6),
  //   var(--yellow-tint-6),
  //   #5b2501
  // );
        }
      }
      .images-sml{
        display: flex;
        justify-content: center;
        align-items: center;
         margin-top: 20px;
         img{
          width: 55px !important;
          height: 55px !important;
          border-radius: 50%;
          object-fit: cover !important;
          cursor: pointer;
          margin: 10px;
          border: 2px solid #5b2501;
          @media screen and (max-width: 900px) {
            width: 40px !important;
          height: 40px !important;
          }
        }
      }
    }

    h1 {
      margin: var(--mb) 0;
      max-width: 99.5rem;
      width: 100%;
      text-align: center;
      color: var(--black-tint-3);
    }

    .products {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 4rem;

      .single_product {
        display: flex;
        justify-content: space-between;
        gap: 2.4rem;
        width: 100%;

        .product_img {
          max-width: 69rem;
          width: 100%;
          height: 46.8rem;
          overflow: hidden;
          border-radius: 2.4rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        // .product_img img {
        //   width: 34.125rem !important;
        //   height: 29.25rem !important;
        // }

        .product_content {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          max-width: 48.6rem;
          width: 100%;
          background-color: var(--yellow-light);
          border-radius: 2.4rem;
          padding: 2.4rem;

          .product_title {
            margin-bottom: 1.6rem;
            color: var(--black-tint-3);
          }

          .justify {
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          p {
            font-family: var(--regular-400) !important;
          }

          .product_price {
            display: flex;
            align-items: flex-end;
            gap: 0.8rem;
            margin: 3.2rem 0;

            h3 {
              font-family: var(--bold-700) !important;
              color: var(--yellow-tint-5);
              font-size: 3.2rem !important;
            }
          }

          .product_buttons {
            display: flex;
            flex-wrap: wrap;
            gap: 1.6rem;

            button {
              background-color: var(--white-main);
              border: 1px solid var(--black-main);
              border-radius: 8px;
              font-size: 1.4rem;
              font-family: var(--medium-500);
              color: var(--black-main);
              cursor: var(--pointer);
              transition: box-shadow 0.15s ease;

              &:hover {
                box-shadow: -4px 4px 0px 0px var(--black-main);
                -webkit-box-shadow: -4px 4px 0px 0px var(--black-main);
                -moz-box-shadow: -4px 4px 0px 0px var(--black-main);
              }
            }

            .product_select {
              background-color: var(--yellow-main);
            }
          }
        }

        &:nth-child(2n + 1) {
          flex-direction: row-reverse;
        }

        @media screen and (max-width: 768px) {
          flex-direction: column-reverse;
          gap: 1.5rem;

          .product_img,
          .product_content {
            max-width: 100%;
          }

          .product_content {
            .justify {
              -webkit-line-clamp: 3;
            }

            .product_price {
              margin: 2rem 0;
            }
          }

          .product_img {
            height: 21.7rem;
          }

          &:nth-child(2n + 1) {
            flex-direction: column-reverse;
          }
        }

        @media screen and (max-width: 445px) {
          .product_img {
            height: 30rem;
          }
        }
      }
    }
  }
}
