.team_container {
  padding: 0 var(--px);
  margin-bottom: var(--mb);

  section {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    .team_card {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(26rem, 1fr));
      gap: 2.4rem;
      padding: 2.4rem 0;
      border-bottom: 1px solid var(--black-main);
      border-top: 1px solid var(--black-main);

      .team {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
        color: var(--black-tint-3);

        .team_card-img {
          width: 100%;
          height: 36.6rem;
          border-radius: 2.4rem;
          overflow: hidden;
          background-color: var(--img-bg);
        }

        h3 {
          font-size: 1.6rem !important;
          font-weight: 500;
        }

        p {
          margin-top: -2rem;
          font-size: 14px !important;
        }
      }
    }
  }
}
