.faq_container {
  padding: 0 var(--px);
  margin-bottom: var(--mb);

  section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2.4rem;

    .accordion_container {
      max-width: 69rem;
      width: 100%;

      .accordion {
        display: flex;
        flex-direction: column;
        border-top: 1px solid var(--black-tint-2);
        user-select: none;

        &:last-child {
          border-bottom: 1px solid var(--black-tint-2);
        }

        .accordion_top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 2rem;
          width: 100%;
          padding: 3.9rem 0 2.3rem;

          h4 {
            color: var(--yellow-tint-5);
          }

          span {
            cursor: var(--pointer);
          }
        }

        p {
          padding-bottom: 3.9rem;
          height: max-content;
          color: var(--black-tint-3);
          font-weight: 500;
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
          transition: height 0.2s ease, padding-bottom 0.2s ease,
            clip-path 0.3s ease;
        }

        .showAccordion {
          padding-bottom: 1rem;
          clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
          height: 0;
        }
      }

      @media screen and (max-width: 1100px) {
        max-width: 100%;
      }
    }
  }
}
