.home_banner {
  width: 100%;
  // height: calc(100vh - 10.4rem);
  padding: var(--px);
  background: var(--yellow-tint-6);
  background: radial-gradient(
    circle at bottom left,
    #5b2501,
    var(--yellow-tint-6),
    var(--yellow-tint-6),
    #5b2501
  );
  overflow: hidden;
  // padding-bottom: ;

  @media screen and (max-width: 700px) {
  height: calc(100vh - 10.4rem);
  }

  section {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 6.1rem;

    .home_banner-left,
    .home_banner-right {
      flex: 1 1 20rem;
    }

    .home_banner-left {
      h1 {
        max-width: 50rem;
        width: 100%;
        color: var(--white-main);
      }

      p {
        font-size: 1.8rem !important;
        max-width: 38rem;
        width: 100%;
        color: var(--white-main);
      }

      .home_banner-btns {
        margin-top: 3.2rem;
        display: flex;
        flex-wrap: wrap;
        gap: 1.6rem;
        height: 5.6rem;

        .banner_btn {
          display: grid;
          place-items: center;
          height: 100%;
          border: 1px solid var(--black-main);
          border-radius: 8px;
          padding: 0 2.4rem;
          font-size: 1.4rem;
          font-family: var(--medium-500);
          color: var(--black-main);
          cursor: var(--pointer);
          transition: box-shadow 0.15s ease;
          text-align: center;

          &:hover {
            box-shadow: -5px 5px 0px 0px var(--white-main);
            -webkit-box-shadow: -5px 5px 0px 0px var(--white-main);
            -moz-box-shadow: -5px 5px 0px 0px var(--white-main);
          }

          &:first-child {
            background-color: var(--yellow-main);
          }

          &:last-child {
            background-color: var(--white-main);
          }
        }
      }
    }

    .home_banner-right {
      height: 100%;
      width: 100%;
      position: relative;

      .banner_star {
        position: absolute;
        animation: scaling 2s ease-out infinite;
      }

      .banner_star.one {
        top: 7rem;
        left: -2rem;
      }

      .banner_star.two {
        top: 9rem;
        right: 3rem;
        animation-delay: 5s;
      }

      .banner_star.three {
        bottom: 13rem;
        left: 3rem;
      }

      .banner_star.four {
        bottom: 3rem;
        left: 13rem;
        animation-delay: 5s;
      }

      @keyframes scaling {
        0%,
        100% {
          transform: scale(1.5);
        }
        50% {
          transform: scale(0.7);
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    section {
      flex-direction: column-reverse;

      .home_banner-left,
      .home_banner-right {
        flex: 1;
      }

      .home_banner-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }

      .home_banner-right {
        width: 37rem;
        height: 37rem;
      }
    }
  }

  @media screen and (max-width: 532px) {
    section {
      flex-direction: column-reverse;
      align-items: flex-end;

      .home_banner-left,
      .home_banner-right {
        flex: 1;
      }

      .home_banner-left {
        align-items: flex-start;
        text-align: left;
        h1,
        p {
          max-width: 100%;
        }
      }

      .home_banner-right {
        width: 37rem;
        height: 37rem;
      }
    }
  }
}
