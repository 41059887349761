footer {
  display: flex;
  flex-direction: column;

  .newsletter_form {
    text-align: center;
    padding: 6.3rem var(--px);
    background: var(--yellow-tint-6);
    background: radial-gradient(
      circle at bottom left,
      #5b2501,
      var(--yellow-tint-6),
      var(--yellow-tint-6),
      #5b2501
    );

    section {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .newsletter_form-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.6rem;
        color: var(--white-main);
      }

      .newsletter_form-control {
        display: flex;
        gap: 2.4rem;
        max-width: 99.6rem;
        width: 100%;

        input {
          width: 100%;
          height: 5.6rem;
          border-radius: 8px;
          background-color: transparent;
          color: var(--white-main);
          font-size: 14px;
          border: 1px solid var(--white-main);
          padding: 1.9rem 2.4rem;

          &::placeholder {
            color: var(--black-tint-1);
          }
        }

        button {
          width: 18rem;
          height: 5.6rem;
          border-radius: 8px;
          border: 1px solid var(--black-main);
          background-color: var(--yellow-main);
          font-size: 1.4rem;
          font-family: var(--medium-500);
          cursor: var(--pointer);
        }

        @media screen and (max-width: 768px) {
          flex-direction: column;

          button {
            width: 100% !important;
          }
        }
      }
    }
  }

  .footer_bottom {
    background-color: var(--yellow-main);
    padding: 4.7rem var(--px);

    p {
      color: var(--black-tint-3);
      font-weight: 600;
      font-size: 1.6rem !important;
      font-family: var(--semiBold-600);
    }

    section {
      display: flex;
      flex-direction: column;
      gap: 5rem;

      .top_section {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 5rem;

        .footer_section-left,
        .footer_section-right {
          flex: 1 1 35rem;
        }

        .footer_section-left {
          display: flex;
          flex-direction: column;
          gap: 3.3rem;

          p {
            max-width: 28.2rem;
            width: 100%;

            @media screen and (max-width: 689px) {
              max-width: 100%;
            }
          }
        }

        .footer_section-right {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 2.4rem;

          .right_section-content {
            flex: 1 1 15rem;
            max-width: 18rem;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1.6rem;

            p {
              font-size: 1.4rem !important;
              font-family: var(--semiBold-600) !important;
              font-weight: 600;
            }

            ul {
              display: flex;
              flex-direction: column;

              .link {
                height: 4rem;
                width: max-content;
                padding: 0 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0.8rem;
                cursor: var(--pointer);
                border-radius: 8px;

                span {
                  margin-bottom: -0.3rem;
                }

                &:hover {
                  background-color: var(--yellow-tint-2);
                }

                p {
                  font-size: 1.6rem !important;
                  font-family: var(--regular-400) !important;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }

      .bottom_section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        flex-wrap: wrap;

        div {
          display: flex;
          align-items: center;
          gap: 3.2rem;
        }
      }

      .hr {
        width: 100%;
        border-bottom: 1px solid var(--black-tint-3);
      }
    }
  }
}
