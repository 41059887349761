.about_container {
  padding: 5rem var(--px) 0;
  margin-bottom: var(--mb);

  section {
    .about_card-1 {
      margin-bottom: var(--mb);
      background-color: var(--yellow-light);
      width: 100%;
      padding: 3.2rem 2.4rem;
      border-radius: 2.4rem;
      display: flex;
      flex-direction: column;
      gap: 9.6rem;

      .card-1 {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 4rem;

        .card-1_top {
          max-width: 38.4rem;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          color: var(--black-tint-3);

          p {
            margin-bottom: 0.8rem;
          }
        }

        .about_card-img {
          max-width: 72rem;
          width: 100%;
          aspect-ratio: 2;
          background-color: var(--img-bg);
          border-radius: 2.4rem;
          overflow: hidden;
        }
      }

      .card-2 {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(28rem, 1fr));
        gap: 7.5rem;

        h3 {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          font-weight: 500;
          margin: 3.2rem 0 1.6rem;

          span {
            color: var(--yellow-main);
          }
        }

        p {
          text-align: justify;
          line-height: 2.25rem;
        }

        .card_2-icon {
          width: 5.6rem;
          max-height: 5.6rem;
          border-radius: 1.6rem;
          background-color: var(--yellow-tint-1);
          padding: 0.5rem;
        }
      }

      @media screen and (max-width: 768px) {
        padding: 4rem 1.6rem;
        gap: 5.6rem;

        .card-1 {
          flex-wrap: wrap;

          .card-1_top,
          .about_card-img {
            max-width: 100%;
          }

          .about_card-img {
            aspect-ratio: 1.7;
          }
        }

        .card-2 {
          gap: 5.6rem;
        }
      }
    }

    .about_card-2 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      gap: 6.4rem;

      .what_we_do {
        max-width: 68.7rem;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
        gap: 7.5rem;

        .what_we_do-card {
          display: flex;
          flex-direction: column;
          gap: 2.4rem;
          color: vat(--black-tint-3);
          width: 100%;

          .what_we_do-card_top {
            display: flex;
            align-items: flex-end;

            div {
              max-width: 7.8rem;
              height: 7.8rem;
              margin-right: 2rem;
              border-radius: 16px;
              background-color: var(--yellow-tint-1);
              padding: 1rem;
            }

            h3 {
              font-weight: 500;
              max-width: 20.8rem;
              width: 100%;
            }

            p {
              font-family: var(--regular-400);
              font-weight: 400;
            }
          }
        }
      }

      @media screen and (max-width: 768px) {
        .what_we_do {
          max-width: 100%;
          gap: 5rem;
        }
      }
    }
  }
}
