header {
  width: 100%;
  height: 10.4rem;
  position: sticky;
  top: 0;
  background-color: var(--yellow-tint-6);
  z-index: 10;

  section {
    width: 100%;
    height: 100%;
    border-bottom: 1px solid transparent;
    background-color: var(--yellow-tint-6);
    position: relative;
    padding: 0 var(--px);

    nav {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;
      z-index: 10;

      .logo {
        margin-right: 5rem;
      }

      .nav_links {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 60.8rem;

        .nav_links-link {
          font-size: 1.4rem;
          color: var(--white-main);
          cursor: var(--pointer);
          position: relative;
          padding: 0.1rem 0.7rem 0.9rem;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          z-index: 1;

          &::before {
            content: "";
            top: 100%;
            position: absolute;
            width: 100%;
            height: 10rem;
            border-radius: 4rem;
            background-color: var(--yellow-main);
            z-index: -1;
            transition: top 0.15s ease-in, background-color 0.1s ease-in;
          }

          &:hover {
            &::before {
              background-color: var(--yellow-tint-2);
              top: 0;
            }
          }
        }

        .activeLink {
          font-family: var(--bold-700);

          &::before {
            top: 80%;
            width: 80%;
            background-color: var(--yellow-main);
            transition: none;
          }

          &:hover {
            &::before {
              top: 80%;
              background-color: var(--yellow-main);
            }
          }
        }
      }

      .nav_right {
        display: flex;
        align-items: center;
        gap: 2rem;

        .nav_btn {
          height: 4rem;
          background-color: transparent;
          color: var(--white-main);
          font-size: 1.4rem;
          padding: 0.5rem 2.4rem;
          border-radius: 8px;
          border: 1px solid var(--white-main);
          display: grid;
          place-items: center;
          cursor: var(--pointer);
          transition: box-shadow 0.15s ease;

          &:hover {
            box-shadow: -4px 4px 0px 0px var(--white-main);
            -webkit-box-shadow: -4px 4px 0px 0px var(--white-main);
            -moz-box-shadow: -4px 4px 0px 0px var(--white-main);
          }
        }

        .nav_hamburger {
          display: none;
          cursor: var(--pointer);
        }
      }
    }

    .mobile_nav {
      display: none;
      flex-direction: column;
      gap: 4rem;
      padding: 10.6rem 1.6rem 4rem;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: var(--white-main);
      transition: top 0.15s ease-in-out;
      z-index: -1;
      pointer-events: all;

      .nav_links-mobile {
        display: flex;
        flex-direction: column;

        .nav_links-mobile_link {
          margin-bottom: 3rem;
          font-size: 3rem;
          font-family: var(--bold-700);
          line-height: 3.62rem;
          color: var(--black-tint-3);
          cursor: var(--pointer);

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      hr {
        width: 16.3rem;
        height: 1px;
        background-color: #cecece;
      }

      .mobile_nav-btn {
        height: 40px;
        width: max-content;
        background-color: transparent;
        color: var(--black-main);
        font-size: 1.4rem;
        padding: 0.5rem 2.4rem;
        border-radius: 8px;
        border: 1px solid var(--black-main);
        display: grid;
        place-items: center;
        cursor: var(--pointer);
        transition: box-shadow 0.15s ease;

        &:hover {
          box-shadow: -4px 4px 0px 0px var(--black-main);
          -webkit-box-shadow: -4px 4px 0px 0px var(--black-main);
          -moz-box-shadow: -4px 4px 0px 0px var(--black-main);
        }
      }
    }

    .mobile_nav-active {
      top: -150%;
      pointer-events: none;
    }

    @media screen and (max-width: 886px) {
      nav {
        .nav_links {
          display: none;
        }

        .nav_right {
          .nav_hamburger {
            display: block;
          }
        }
      }

      .mobile_nav {
        display: flex;
      }
    }

    @media screen and (max-width: 768px) {
      nav {
        .nav_right {
          .nav_btn {
            display: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    height: 8rem;
  }
}

.activeHeader {
  background-color: var(--white-main);

  section {
    background-color: var(--white-main);

    .nav_links {
      .nav_links-link {
        color: var(--black-main);
      }
    }

    .nav_right {
      .nav_btn {
        color: var(--black-main);
        border-color: var(--black-main);

        &:hover {
          box-shadow: -4px 4px 0px 0px var(--black-main);
          -webkit-box-shadow: -4px 4px 0px 0px var(--black-main);
          -moz-box-shadow: -4px 4px 0px 0px var(--black-main);
        }
      }
    }
  }
}
