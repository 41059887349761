.testimonials_container {
  padding: 0 var(--px);
  margin-bottom: var(--mb);

  section {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    .testimonials {
      .testimonial_card {
        padding: 4rem 0;
        border-top: 1px solid var(--black-tint-2);

        .testimonial {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 2.5rem;
          max-width: 99.6rem;
          width: 100%;
          margin: 0 auto;

          .user_profile {
            display: flex;
            align-items: center;
            height: max-content;

            .user_profile-img {
              width: 5.6rem;
              height: 5.6rem;
              border-radius: 50%;
              background-color: var(--img-bg);
              margin-right: 2.4rem;
              overflow: hidden;
            }

            .user_profile-detail {
              .name {
                font-size: 1.6rem;
                color: var(--black-tint-3);
                font-family: var(--bold-700) !important;
              }

              .role {
                font-size: 1.4rem !important;
                color: var(--yellow-tint-4);
                font-family: var(--regular-400);
              }
            }
          }

          .user_content {
            max-width: 58.8rem;
            width: 100%;
            color: var(--black-tint-3);

            h4 {
              margin-bottom: 2.4rem;
              color: var(--yellow-tint-5);
            }

            .video {
              width: 100%;
              aspect-ratio: 1.7;
              background-color: var(--img-bg);
              border-radius: 2.4rem;
              overflow: hidden;
              position: relative;

              .video_overlay {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 20.6rem;
                display: flex;
                align-items: flex-end;
                padding: 3.8rem 3.7rem;
                background: linear-gradient(
                  rgba(0, 0, 0, 0),
                  rgba(0, 0, 0, 0.5)
                );

                .video_overlay-play {
                  cursor: var(--pointer);
                }
              }
            }

            @media screen and (max-width: 768px) {
              max-width: 100%;

              p {
                text-align: left;
              }

              .video {
                aspect-ratio: 1.5;
              }
            }
          }
        }

        &:last-child {
          border-bottom: 1px solid var(--black-tint-2);
        }
      }
    }
  }
}
