.product_details {
  padding: 0 var(--px);

  .img_show h3 {
    text-align: left;
    margin-bottom: var(--mb-sm);
  }

  .img_show {
    margin-bottom: var(--mb);
  }

  .img_show .list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .branding {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
    grid-gap: 5rem;

    .branding_materials {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 2rem;

      .branding_img {
        width: 100%;
        aspect-ratio: 1.6;
        border-radius: 1.5rem;
        overflow: hidden;

        img {
          border-radius: inherit;
          object-fit: cover;
        }
      }
    }
  }

  // .img_show div {
  //   width: 50%;
  //   text-align: left;
  //   margin-bottom: 50px;

  //   @media screen and (max-width: 768px) {
  //     width: 100%;
  //   }
  // }

  .list_sm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4.6rem;
    flex-wrap: wrap;
    width: 100% !important;
    margin-bottom: 0px !important;

    div {
      width: fit-content !important;
      height: fit-content !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        max-width: 100%;
        width: 35.5625rem;
        margin-bottom: 1.5rem;
        border-radius: 1.5rem;
      }

      span {
        color: var(--black-tint-3);
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2rem;
      }
    }

    img {
      object-fit: contain !important;
      width: 10.9375rem !important;
      height: 8.25rem !important;
    }
  }

  .machines {
    display: flex;
    flex-direction: column;

    .machines_img {
      border-radius: 1.5rem;
      border: var(--border);
      width: 15.3rem;
      height: 15.3rem;

      img {
        width: 15.3rem !important;
        height: 15.3rem !important;
      }
    }
  }

  // .machines img {

  // }
  // .machines span {
  //   margin-right: auto;
  // }

  section {
    display: flex;
    flex-direction: column;
    text-align: center;

    .back {
      margin: 7.3rem 0;
      display: flex;
      align-items: center;
      gap: 2.3rem;
      cursor: var(--pointer);
      width: max-content;
    }

    .details_banner_img {
      margin-bottom: var(--mb);
      border-radius: 2.4rem;
    }

    .details_banner {
      background-color: #f7c4cd;
      width: 100%;
      aspect-ratio: 2.5;
      border-radius: 2.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 4.8rem;
      margin-bottom: var(--mb-sm);
      color: #cc1311;

      p {
        margin: 1.6rem 0 3.2rem;
      }

      button {
        border-radius: 8px;
        border: 1px solid var(--black-main);
        font-size: 1.4rem;
        font-family: var(--medium-500) !important;
        cursor: var(--pointer);
      }
    }

    .product_concept {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2.4rem;
      color: var(--black-tint-3);
      margin-bottom: var(--mb);

      p {
        max-width: 43.8rem;
        width: 100%;

        @media screen and (max-width: 768px) {
          max-width: 100%;
        }
      }
    }

    .product_details-img {
      width: 100%;
      aspect-ratio: 2.1;
      overflow: hidden;
      border-radius: 2.4rem;
      background-color: var(--img-bg);
      margin-bottom: var(--mb);
    }

    .product_service-container {
      display: flex;
      flex-direction: column;

      .product_services {
        margin-bottom: var(--mb);
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2.7rem;
        color: var(--black-tint-3) !important;

        .product_services-content {
          display: flex;
          flex-direction: column;
          margin-bottom: 6.2rem;
          align-items: flex-start;

          p {
            text-align: left;
            margin-bottom: var(--mb-sm);
          }

          .wws_list h4 {
            text-align: left;
            font-size: 1.4em !important;
          }

          ul {
            display: flex;
            flex-direction: column;
            margin-top: 2.4rem;

            p {
              text-align: left;
              padding-left: 1.5rem;
              max-width: 38.4rem;
              width: 100%;

              li {
                position: relative;

                &::before {
                  content: "•";
                  left: -1.3rem;
                  position: absolute;
                }
              }

              @media screen and (max-width: 768px) {
                max-width: 100%;
              }
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        .services_right {
          max-width: 69rem;
          width: 100%;
          height: 50.3rem;
          overflow: hidden;
          border-radius: 2.4rem;
          background-color: var(--img-bg);
        }

        .mr {
          margin-right: 3rem;
        }
        .ml {
          margin-left: 3rem;
        }

        .services_right.img_sm {
          height: 40.9375rem;
        }

        @media screen and (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;

          .product_services-content {
            margin-bottom: 3.2rem;

            ul {
              margin-top: 2.4rem;
            }
          }

          .services_right {
            max-width: 100%;
          }
        }

        @media screen and (max-width: 502px) {
          .services_right {
            height: 25rem;
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      .back {
        margin: 4.3rem 0;
      }

      .details_banner {
        padding: 2.4rem;

        p {
          margin: 1rem 0 2rem;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .menu_img {
      background: transparent !important;
    }
    .menu_img img {
      border-radius: 3rem !important;
      object-fit: contain !important;
    }
  }
}
