.vendor-container {
  padding: 30px 0px;
}
.next-prev {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.next-prev button {
  background-color: var(--yellow-main);
  border: 1px solid var(--black-main);
  border-radius: 8px;
  font-size: 1.4rem;
  font-family: var(--medium-500);
  color: var(--black-main);
  cursor: var(--pointer);
  transition: box-shadow 0.15s ease;
  width: 100%;
  width: 48%;
  max-width: 200px;
}
.next-prev button:hover {
  box-shadow: -4px 4px 0px 0px var(--black-main);
  -webkit-box-shadow: -4px 4px 0px 0px var(--black-main);
  -moz-box-shadow: -4px 4px 0px 0px var(--black-main);
}
.vendor-container h3 {
  color: #5b2501;
}
.vendor-container .form_container {
  display: unset;
  align-items: unset;
  justify-content: unset;
  flex-direction: unset;
  padding: unset;
}
.check-container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.check-container p {
  margin-left: 5px;
  font-size: 1.4em !important;
}
.status-track {
  width: 100%;
  height: 10px;
  background-color: #ddd;
  margin-bottom: 20px;
}
.status-track div {
  height: 100%;
  background-color: #5b2501;
  transition: 0.3s all ease-in-out;
}
@media (max-width: 1200px) {
  .vendor-container {
    padding: 20px;
  }
  .check-container input {
    width: 20px;
    height: 20px;
  }
}
