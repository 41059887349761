.partners_section {
  padding: 7.2rem var(--px) 0;
  margin-bottom: var(--mb);

  .partners_section-top {
    border-bottom: 1px solid var(--black-tint-1);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .partners_section-heading {
      font-family: var(--sgSb) !important;
    }

    .partners_section-partners {
      max-width: 100rem;
      width: 100%;
      padding: 5.8rem 0 3.6rem;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
      gap: 3.1rem;

      .partner {
        flex: 1 1 15rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.8rem;

        .partner_img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-color: var(--img-bg);
          overflow: hidden;
        }

        p {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          color: var(--black-tint-4);
        }

        @media screen and (max-width: 375px) {
          flex: 1 1 20rem;
        }

        @media screen and (max-width: 370px) {
          justify-content: flex-start;
        }
      }
    }
  }

  .partners_section-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5rem;
    flex-wrap: wrap;
    padding: var(--mb) 0 0;
    max-width: 72.6rem;
    width: 100%;
    margin: 0 auto;
    position: relative;

    .partner_star {
      position: absolute;
      animation: scaling 2s ease-out infinite;
    }

    .partner_star.one {
      left: -10rem;
      animation-delay: 3s;
    }

    .partner_star.two {
      top: 7rem;
      left: 45%;
      transform: translateX(-50%);
    }

    .partner_star.three {
      right: -10rem;
      bottom: -1rem;
      animation-delay: 8s;
    }

    @keyframes scaling {
      0%,
      100% {
        transform: scale(1.5);
      }
      50% {
        transform: scale(0.7);
      }
    }

    @media screen and (max-width: 900px) {
      justify-content: space-evenly;
    }

    .partners_section-bottom_product {
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        font-weight: 500;
        font-family: var(--sgMd) !important;
        color: var(--yellow-tint-5);
      }

      p {
        color: var(--black-tint-3);
      }
    }
  }
}
