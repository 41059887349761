.openings_container {
  padding: 0 var(--px);
  margin-bottom: var(--mb);

  section {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    .openings {
      .openings_content {
        display: flex;
        flex-direction: column;
        gap: 2.6rem;
        padding: 4rem 0;
        border-top: 1px solid var(--black-tint-3);

        &:last-child {
          border-bottom: 1px solid var(--black-tint-3);
        }

        .openings_content-top {
          max-width: 31.1rem;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 1.65rem;

          h4 {
            color: var(--black-tint-3);
          }

          div {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
              display: flex;
              align-items: center;
              color: var(--black-tint-2);
              font-family: var(--regular-400);
              font-size: 1.4rem !important;

              span {
                margin: 0.5rem 1rem 0 0;
              }
            }
          }
        }

        .openings_content-bottom {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 2.4rem;
          color: var(--black-tint-3);

          p {
            max-width: 89.4rem;
            width: 100%;
          }

          .openings_btn {
            width: 18rem;
            background-color: var(--yellow-main);
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1.3rem;
            color: var(--black-main);
            border-radius: 8px;
            border: 1px solid var(--black-main);
            cursor: var(--pointer);
            transition: box-shadow 0.15s ease;
            font-size: 1.4rem;
            font-family: var(--medium-500);

            &:hover {
              box-shadow: -5px 5px 0px 0px var(--black-main);
              -webkit-box-shadow: -5px 5px 0px 0px var(--black-main);
              -moz-box-shadow: -5px 5px 0px 0px var(--black-main);
            }
          }
        }
      }
    }
  }
}
