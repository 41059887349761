.form_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: var(--mb) var(--px);
  gap: 4.5rem;

  .form_container-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    text-align: center;

    p {
      max-width: 38.4rem;
      width: 100%;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    max-width: 60rem;
    width: 100%;

    .form_inputs {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
      grid-gap: 2rem;

      .form_control {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        label {
          font-size: 1.4rem;
        }

        .input_holder {
          width: 100%;
          height: 5.6rem;
          display: flex;
          align-items: center;
          border-radius: 0.8rem;
          border: 1px solid var(--black-tint-2);
          padding: 0.5rem 1.5rem;

          input {
            width: 100%;
            height: 100%;
            background-color: var(--white-main);
          }
        }

        .PhoneInput {
          width: 100%;

          .PhoneInputCountryIcon {
            width: 2rem;
            height: 1.5rem;
          }

          .PhoneInputCountrySelectArrow {
            margin: 0 1rem 0 0.5rem;
          }
        }
      }
    }

    button {
      background-color: var(--yellow-main);
      border: 1px solid var(--black-main);
      border-radius: 8px;
      font-size: 1.4rem;
      font-family: var(--medium-500);
      color: var(--black-main);
      cursor: var(--pointer);
      transition: box-shadow 0.15s ease;

      &:hover {
        box-shadow: -4px 4px 0px 0px var(--black-main);
        -webkit-box-shadow: -4px 4px 0px 0px var(--black-main);
        -moz-box-shadow: -4px 4px 0px 0px var(--black-main);
      }
    }
  }

  @media screen and (max-width: 768px) {
    form {
      .form_inputs {
        max-width: 100%;
        grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
      }
    }
  }
}

.purchase-terms ul li{
  list-style: circle !important;
}
.purchase-terms h4{
  margin: 10px 0px;
}
.terms-link{
  color: var(--yellow-main);
  text-decoration: underline;
  font-weight: 700;
}
.accept span{
  display: block;
  margin-left: 5px;
}
.accept{
  display: flex;
  align-items: center;
}
.disabled-btn:disabled, button:disabled{
  opacity: 0.3;
  background-color: #ccc;
}