.gallery_container {
  padding: 0 var(--px);
  margin-bottom: var(--mb);

  section {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      margin: var(--mb) 0;
      max-width: 99.5rem;
      width: 100%;
      text-align: center;
      color: var(--black-tint-3);
    }

    .grid {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .gallery_img {
        border-radius: 2.4rem;
        background-color: var(--img-bg);
      }

      .three_fr,
      .six_fr {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        gap: 2.4rem;
      }

      .gallery_img {
        position: relative;
        overflow: hidden;
        overflow-wrap: break-word;

        .gallery_btn {
          width: 4.8rem;
          height: 4.8rem;
          border-radius: 2.4rem;
          position: absolute;
          top: 1rem;
          right: 1rem;
          background-color: var(--white-main);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: var(--pointer);
          border: 1px solid transparent;
          transition: width 0.15s ease-in-out,
            background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
        }

        .gallery_content {
          position: absolute;
          bottom: -100%;
          right: 1rem;
          max-width: 30rem;
          width: 90%;
          padding: 2.4rem;
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 16px;
          color: var(--white-main);
          transition: bottom 0.15s ease-in-out;

          h4 {
            margin: 2.4rem 0 0.8rem;
          }

          p {
            font-size: 1.4rem !important;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;

            &:first-child {
              font-family: var(--medium-500) !important;
            }
          }

          @media screen and (max-width: 768px) {
            padding: 1.4rem;
          }
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          opacity: 0;
          transition: opacity 0.15s ease-in-out;
        }

        &:hover {
          &::before {
            opacity: 1;
          }

          .gallery_content {
            bottom: 1rem;
          }

          .gallery_btn {
            width: 6.4rem;
            border-color: var(--black-main);
            background-color: var(--yellow-main);
          }
        }
      }

      .three_fr {
        grid-template-rows: repeat(3, 1fr);

        .gallery_img {
          grid-row-start: 1;

          &:nth-child(1) {
            grid-column-start: 1;
            grid-row-end: 4;
            grid-column-end: 4;
          }

          &:nth-child(2) {
            grid-column-start: 4;
            grid-row-end: 4;
            grid-column-end: 7;
          }

          &:nth-child(3) {
            grid-column-start: 7;
            grid-row-end: 4;
            grid-column-end: 11;
          }
        }
      }

      .six_fr {
        grid-template-rows: repeat(5, 1fr);

        .gallery_img {
          &:nth-child(1) {
            grid-row-start: 1;
            grid-column-start: 1;
            grid-row-end: 3;
            grid-column-end: 5;
          }

          &:nth-child(2) {
            grid-row-start: 1;
            grid-column-start: 5;
            grid-row-end: 4;
            grid-column-end: 7;
          }

          &:nth-child(3) {
            grid-row-start: 1;
            grid-column-start: 7;
            grid-row-end: 4;
            grid-column-end: 9;
          }

          &:nth-child(4) {
            grid-row-start: 3;
            grid-column-start: 1;
            grid-row-end: 6;
            grid-column-end: 3;
          }

          &:nth-child(5) {
            grid-row-start: 3;
            grid-column-start: 3;
            grid-row-end: 6;
            grid-column-end: 5;
          }

          &:nth-child(6) {
            grid-row-start: 4;
            grid-column-start: 5;
            grid-row-end: 6;
            grid-column-end: 9;
          }
        }
      }

      @media screen and (max-width: 768px) {
        .three_fr,
        .six_fr {
          grid-template-columns: repeat(4, 1fr);
        }

        .three_fr {
          grid-template-rows: 1fr, 2rem, 10%, auto, repeat(2, 10rem),
            minmax(2rem, auto);

          .gallery_img {
            &:nth-child(1) {
              grid-row-start: 1;
              grid-column-start: 1;
              grid-row-end: 4;
              grid-column-end: 3;
            }

            &:nth-child(2) {
              grid-row-start: 4;
              grid-column-start: 1;
              grid-row-end: 14;
              grid-column-end: 5;
            }

            &:nth-child(3) {
              grid-row-start: 1;
              grid-column-start: 3;
              grid-row-end: 4;
              grid-column-end: 5;
            }
          }
        }

        .six_fr {
          grid-template-rows: 1fr, 2rem, 10%, auto, repeat(2, 10rem),
            minmax(2rem, auto);

          .gallery_img {
            &:nth-child(1) {
              grid-row-start: 1;
              grid-column-start: 1;
              grid-row-end: 3;
              grid-column-end: 5;
            }

            &:nth-child(2) {
              grid-row-start: 3;
              grid-column-start: 1;
              grid-row-end: 6;
              grid-column-end: 3;
            }

            &:nth-child(3) {
              grid-row-start: 3;
              grid-column-start: 3;
              grid-row-end: 6;
              grid-column-end: 5;
            }

            &:nth-child(4) {
              grid-row-start: 6;
              grid-column-start: 1;
              grid-row-end: 9;
              grid-column-end: 3;
            }

            &:nth-child(5) {
              grid-row-start: 6;
              grid-column-start: 3;
              grid-row-end: 9;
              grid-column-end: 5;
            }

            &:nth-child(6) {
              grid-row-start: 9;
              grid-column-start: 1;
              grid-row-end: 11;
              grid-column-end: 5;
            }
          }
        }
      }

      @media screen and (max-width: 435px) {
        .three_fr,
        .six_fr {
          display: flex;
          flex-direction: column;

          .gallery_img {
            height: 30rem;
          }
        }
      }
    }
  }
}
