.contact_container {
  padding: 0 var(--px);
  margin-bottom: var(--mb);

  section {
    .contact {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: var(--black-tint-3);
      margin-bottom: var(--mb);

      .contact_cards {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(28.2rem, 1fr));
        grid-auto-rows: 28.2rem;
        grid-column-gap: 8.4rem;
        grid-row-gap: 3.2rem;

        .contact_card-content {
          background-color: var(--yellow-light);
          // aspect-ratio: 1.3;
          border-radius: 2.4rem;
          padding: 2.4rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          color: var(--black-tint-3);

          .contact_card-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 5.6rem;
            height: 5.6rem;
            border-radius: 1.6rem;
            background-color: var(--yellow-tint-1);
          }

          h4 {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            margin: 3.2rem 0 1.6rem;

            span {
              color: var(--yellow-main);
            }
          }

          p {
            font-weight: 400;
          }
        }
      }
    }

    .contact_form {
      background-color: var(--yellow-tint-6);
      border-radius: 2.4rem;
      padding: 6.4rem 1.5rem;
      color: var(--white-main);

      .form {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-width: 58.8rem;
        margin: 0 auto;
        position: relative;

        .contact_star {
          position: absolute;
          animation: scaling 2s ease-out infinite;
        }

        .contact_star.one {
          top: -1rem;
          left: -8rem;
          animation-delay: 3s;
        }

        .contact_star.two {
          top: -1rem;
          right: -8rem;
        }

        .contact_star.three {
          bottom: -3rem;
          left: -15rem;
          animation-delay: 3s;
        }

        .contact_star.four {
          bottom: 3rem;
          right: -15rem;
        }

        @keyframes scaling {
          0%,
          100% {
            transform: scale(1.5);
          }
          50% {
            transform: scale(0.7);
          }
        }

        form {
          display: flex;
          flex-direction: column;
          gap: 2.4rem;
          max-width: 58.8rem;
          width: 100%;

          .inputs_container {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(28.2rem, 1fr));
            grid-gap: 2.4rem;
          }

          .form_control {
            display: flex;
            flex-direction: column;

            p {
              margin-bottom: 0.8rem;
              font-family: var(--medium-500);
            }

            input,
            textarea {
              width: 100%;
              background-color: transparent;
              border: 1px solid var(--white-main);
              border-radius: 8px;
              padding: 2.4rem;
              color: inherit;
              font-family: var(--medium-500);
              font-size: 1.4rem;

              &::placeholder {
                color: var(--black-tint-2);
              }
            }

            input {
              height: 5.6rem;
            }

            textarea {
              height: 15.2rem;

              &::-webkit-scrollbar {
                display: none;
              }
            }
          }

          .form_btn {
            background-color: var(--yellow-main);
            font-size: 1.4rem;
            color: var(--black-main);
            font-family: var(--medium-500);
            border-radius: 8px;
            transition: box-shadow 0.15s ease;
            cursor: var(--pointer);

            &:hover {
              box-shadow: -5px 5px 0px 0px var(--black-main);
              -webkit-box-shadow: -5px 5px 0px 0px var(--black-main);
              -moz-box-shadow: -5px 5px 0px 0px var(--black-main);
            }
          }
        }

        @media screen and (max-width: 768px) {
          padding: 4rem 1.5rem;
        }
      }
    }
  }
}
