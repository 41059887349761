.news_blog {
  padding: 0 var(--px);
  margin-bottom: var(--mb);

  section {
    display: flex;
    justify-content: space-between;
    gap: 2.4rem;

    .blog_left-card,
    .blog_right-card {
      background-color: var(--img-bg);
      border-radius: 2.4rem;
      width: 100%;
      position: relative;
      padding: 2.4rem;
      display: flex;
      align-items: flex-end;
      color: var(--white-main);
      overflow: hidden;

      .blog_btn {
        z-index: 1;
        width: 4.8rem;
        height: 4.8rem;
        border-radius: 2.4rem;
        position: absolute;
        top: 1rem;
        right: 1rem;
        background-color: var(--white-main);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: var(--pointer);
        border: 1px solid transparent;
        transition: width 0.15s ease-in-out, background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out;
      }

      img, iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: transform 0.1s ease-in-out;
      }

      .left_card-content,
      .right_card-content {
        max-width: 37.6rem;
        width: 100%;
        border-radius: 1.6rem;
        z-index: 1;

        .date {
          padding: 0 1.2rem;
          height: 3.4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid var(--white-main);
          border-radius: 17px;
          font-size: 1.4rem !important;
          width: max-content;
        }

        h4 {
          margin: 2.4rem 0 0.8rem;
        }

        .content {
          margin-bottom: 1.6rem;
        }

        .source {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          font-size: 1.4rem !important;

          span {
            color: var(--yellow-main);
          }
        }
      }

      .right_card-content {
        .content {
          color: var(--yellow-tint-1);
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1;
      }

      &:hover {
        .blog_btn {
          width: 6.4rem;
          border-color: var(--black-main);
          background-color: var(--yellow-main);
        }

        img {
          transform: scale(1.1);
        }
      }
    }

    .blog_left {
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
      max-width: 77.6rem;
      width: 100%;

      .blog_left-card {
        height: 45.6rem;

        .left_card-content {
          padding: 2.4rem;
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }

    .blog_right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 2.4rem;
      max-width: 37.6rem;
      width: 100%;

      .blog_right-card {
        aspect-ratio: 1.5;
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;

      .blog_left,
      .blog_right {
        max-width: 100%;
      }

      .blog_left-card,
      .blog_right-card {
        .left_card-content,
        .right_card-content {
          max-width: 100%;
        }
      }

      .blog_right-card {
        aspect-ratio: 1.5;
      }
    }
  }
}
