.location_container {
  margin-bottom: var(--mb);

  section {
    .operator {
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
      margin-bottom: var(--mb);
      padding: 0 var(--px);

      .operator_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2.4rem;
        flex-wrap: wrap;

        h2 {
          max-width: 35rem;
          width: 100%;
        }

        .operator_top-btn {
          background-color: var(--yellow-main);
          color: var(--black-main);
          font-size: 1.4rem;
          font-weight: 500;
          border: 1px solid var(--black-main);
          border-radius: 8px;
          cursor: var(--pointer);
          transition: box-shadow 0.15s ease;

          &:hover {
            box-shadow: -5px 5px 0px 0px var(--black-main);
            -webkit-box-shadow: -5px 5px 0px 0px var(--black-main);
            -moz-box-shadow: -5px 5px 0px 0px var(--black-main);
          }
        }
      }

      .operator_bottom {
        overflow: hidden;
        width: 100%;
        height: 42.9rem;
        border-radius: 2.4rem;
        background-color: var(--img-bg);

        @media screen and (max-width: 540px) {
          height: 34.2rem;
        }
      }
    }

    .location {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      color: var(--black-tint-3);
      overflow-x: hidden;

      .location_map {
        position: relative;

        svg {
          g {
            cursor: var(--pointer);
          }
        }

        .tooltip {
          max-width: 17rem;
          width: 100%;
          padding: 1.6rem;
          border-radius: 6px;
          background-color: var(--white-main);
          text-align: center;
          border: 1px solid var(--black-main);
          position: absolute;
          transform: translate(-50%, -50%);
          z-index: 1;
          transition: opacity 0.2s ease;
          opacity: 0;
          pointer-events: none;

          p {
            font-size: 1.4rem !important;
            color: var(--black-main);
            z-index: 2;
          }

          &::before {
            z-index: -1;
            content: "";
            position: absolute;
            bottom: -1.8rem;
            left: 50%;
            transform: translateX(-50%) rotate(-45deg);
            width: 3.5rem;
            height: 3.5rem;
            background-color: inherit;
            border-left: inherit;
            border-bottom: inherit;
          }
        }

        .tooltip.one {
          top: 34%;
          left: 46.59%;
        }

        .tooltip.two {
          top: 24%;
          left: 60.5%;
        }

        .show_tooltip {
          opacity: 1;
        }

        @media screen and (max-width: 768px) {
          .tooltip {
            transform: scale(0.7);
          }

          .tooltip.one {
            left: 41%;
            top: 33%;
          }

          .tooltip.two {
            left: 55%;
          }
        }
      }
    }
  }
}
