.showcase_container {
  padding: 0 var(--px);
  margin-bottom: var(--mb);

  section {
    .showcase_filters {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5rem;
      padding: 2rem 0;
      border-bottom: 1px solid var(--black-tint-2);
      border-top: 1px solid var(--black-tint-2);

      .filter_buttons {
        display: flex;
        flex: 1;
        justify-content: flex-end;

        .swiper-slide {
          border: 0.5rem solid transparent;
          height: 4.8rem;
          border-radius: 1rem;
          padding: 0 1rem;
          cursor: var(--pointer);
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s ease;

          p {
            font-size: 1.4rem !important;
            color: var(--black-main);
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          &:hover {
            background-color: var(--yellow-tint-1);
            border-color: var(--white-main);
          }

          &-thumb-active {
            border-color: var(--yellow-tint-2);

            &:hover {
              background-color: var(--white-main);
              border-color: var(--yellow-tint-2);
            }
          }
        }
      }

      .drop_down {
        display: none;
        position: relative;
        cursor: var(--pointer);

        .drop_down-top {
          display: flex;
          align-items: center;
          gap: 1rem;

          p {
            color: var(--black-tint-3);
          }
        }

        .drop_down-container {
          position: absolute;
          top: 100%;
          right: 0;
          margin-top: 1.5rem;
          z-index: 3;
          background-color: var(--yellow-tint-2);
          width: max-content;
          border: 1px solid var(--black-main);
          border-radius: 1rem;
          padding: 1.5rem;

          p {
            color: var(--black-main);
            padding: 1rem;
          }
        }
      }

      @media screen and (max-width: 768px) {
        gap: 2rem;

        .drop_down {
          display: block;
        }

        .filter_buttons {
          display: none;
        }
      }
    }
  }
  .swiper.products {
    position: relative;
    z-index: 1;

    .swiper-slide {
      section.slide {
        display: flex;
        gap: 2.4rem;
        position: relative;
        padding: 2.4rem 0;
        border-bottom: 1px solid var(--black-tint-2);

        .product_img {
          max-width: 69rem;
          width: 100%;
          height: 46.8rem;
          overflow: hidden;
          border-radius: 2.4rem;
        }

        .product_content {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          max-width: 48.6rem;
          width: 100%;
          background-color: var(--yellow-light);
          border-radius: 2.4rem;
          padding: 2.4rem;

          .product_title {
            margin-bottom: 1.6rem;
            color: var(--black-tint-3);
          }

          .justify {
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          p {
            font-family: var(--regular-400) !important;
          }

          .product_price {
            display: flex;
            align-items: flex-end;
            gap: 0.8rem;
            margin: 3.2rem 0;

            h3 {
              font-family: var(--bold-700) !important;
              color: var(--yellow-tint-5);
              font-size: 3.2rem !important;
            }
          }

          .product_buttons {
            display: flex;
            flex-wrap: wrap;
            gap: 1.6rem;

            button {
              background-color: var(--white-main);
              border: 1px solid var(--black-main);
              border-radius: 8px;
              font-size: 1.4rem;
              font-family: var(--medium-500);
              color: var(--black-main);
              cursor: var(--pointer);
              transition: box-shadow 0.15s ease;

              &:hover {
                box-shadow: -4px 4px 0px 0px var(--black-main);
                -webkit-box-shadow: -4px 4px 0px 0px var(--black-main);
                -moz-box-shadow: -4px 4px 0px 0px var(--black-main);
              }
            }

            .product_select {
              background-color: var(--yellow-main);
            }
          }
        }

        @media screen and (max-width: 768px) {
          flex-direction: column;
          gap: 1.5rem;

          .product_img,
          .product_content {
            max-width: 100%;
          }

          .product_content {
            .justify {
              -webkit-line-clamp: 3;
            }

            .product_price {
              margin: 2rem 0;
            }
          }
        }

        @media screen and (max-width: 445px) {
          .product_img {
            height: 30rem;
          }
        }
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      position: absolute;
      top: 50%;
      z-index: 10;
      cursor: var(--pointer);
      color: var(--yellow-main);
      pointer-events: all;
      transition: opacity 0.2s ease;

      &::after {
        font-size: 5rem;
      }

      @media screen and (max-width: 768px) {
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        border: 2px solid var(--yellow-main);
        background-color: var(--yellow-main);
        color: var(--white-main);

        &::after {
          font-size: 2rem;
        }

        top: 25%;
      }
    }

    .swiper-button-disabled {
      opacity: 0;
      pointer-events: none;
    }
  }
}
